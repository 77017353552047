// Components
import SubscriptionForm from "./SubscriptionForm";

// Constants
// import { FOOTER_MARKET_LINKS } from "../../constants/footerLinks";
import { SOCIAL_MEDIA_LINKS } from "../../enums/Navlinks";

export const Footer = () => {
  return (
    <footer className="w-screen  bg-white z-40 mt-32 border-t-2">
      <div className="flex flex-col sm:flex-col lg:flex-row justify-between w-5/6 m-auto py-12">
        <div className="flex flex-col lg:w-80 w-full">
          <h2 className="text-[18px] font-bold px-1">
            Get the latest {`<broCode.ai>`} updates
          </h2>
          <SubscriptionForm />
        </div>

        <div className="flex flex-col sm:mt-">
          <h2 className="text-[18px] font-bold px-1 hidden lg:block">
            Join {`<broCode.ai>`} community
          </h2>
          <div className="px-1 flex flex-row mt-1 justify-between">
            {SOCIAL_MEDIA_LINKS.map((link, idx) => (
              <a href={link.uri} target="_blank" rel="noreferrer" key={idx}>
                <link.icon
                  size={"1.5rem"}
                  className="mx-3 opacity-50 hover:opacity-100"
                />
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full">
        <hr className="border-1 border-gray-300 w-[95%] md:w-5/6 m-auto" />
        <div className="flex flex-col md:flex-row md:justify-center items-center w-5/4 m-auto text-[13px] p-10 text-gray-400/[0.9] font-semibold">
          <div className="hidden md:block px-[14px]">
            <p>&#169; {`<broCode.ai>`}, Inc. All rights reserved.</p>
          </div>
          <div className="cursor-pointer flex flex-row py-[5px] justify-center">
            <a
              href="/guidelines"
              className="hover:text-black px-[8px] md:px-[14px]"
            >
              Company Guidelines
            </a>
            <a href="/terms" className="hover:text-black px-[8px] md:px-[14px]">
              Terms
            </a>
            <a
              href="/privacy"
              className="hover:text-black px-[8px] md:px-[14px]"
            >
              Privacy Policy
            </a>
          </div>

          <div className="block md:hidden pt-5">
            <p>&#169; {`<broCode.ai>`}, Inc. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};
