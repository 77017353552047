import { useState, useEffect, useMemo } from "react";
import { useUserStateContext } from "../../provider/userStateProvider";
import { getFirestoreDocumentById } from "../../firebaseModule/Queries";
import { DocumentData } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { ChangeApp } from "../../apis/changeApp";
import { FireEvent } from "../../helpers/fireEvent";
import { Events } from "../../enums/Events";

export const TweakApp = () => {
  const context = useUserStateContext();
  const [docData, setDocData] = useState<DocumentData | null>(null);
  const [request, setRequest] = useState<string>("");
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [selectedElementId, setSelectedElementId] = useState<string>("");
  const { uid } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  FireEvent(Events.ViewTweakWebsitePage, {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFirestoreDocumentById(uid ?? "", "users");
        setDocData(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [uid]);

  useEffect(() => {
    const fetchHtml = async () => {
      setIsLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/generatedWebsite?uid=${uid}`
      );
      const html = await response.text();
      setHtmlContent(html);
      setIsLoading(false);
    };
    fetchHtml();
  }, [uid]);

  const onClickSubmit = async (searchString: string) => {
    try {
      await ChangeApp(
        searchString,
        request,
        context?.user?.uid ?? "",
        context?.user?.userToken ?? "",
        context?.user?.chatHistory ?? [],
        selectedElementId,
        setIsLoading
      );
    } catch (error) {
      setIsLoading(false);
    }
  };

  useMemo(() => {
    if (!context?.isAuthenticated) {
      context?.setIsOpenSignInModal(true);
    } else {
      context?.setIsOpenSignInModal(false);
    }
  }, [context?.isAuthenticated]);

  if (!docData || isLoading) {
    return <LoadingIndicator />;
  }

  const handleElementClick = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;
    const elementId = clickedElement.id;
    const substring = "tweak-app";
    if (!elementId.includes(substring)) {
      setSelectedElementId(elementId);
    }
  };

  const handleElementHover = (event: MouseEvent) => {
    const clickedElement = event.target as HTMLElement;
    const elementId = clickedElement.id;
    const element = document.getElementById(elementId);
    const substring = "tweak-app";

    if (element && !elementId.includes(substring)) {
      element.classList.add("hover:border-2");
      element.classList.add("hover:border-black");
    }
  };

  window.addEventListener("click", handleElementClick);

  window.addEventListener("mouseover", handleElementHover);

  return (
    <div className="flx-col justify-between h-screen pt-16 " id="tweak-app">
      <div
        className="h-[80vh] w-full border-[1px] border-gray-800 overflow-scroll"
        id="tweak-app-container-one"
      >
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      </div>
      <div
        className="bg-white rounded-lg shadow-md h-[12vh] w-full pt-6 px-12 "
        id="tweak-app-container-two"
      >
        <div
          className="flex justify-between items-center"
          id="tweak-app-container-three"
        >
          <span id="tweak-app-span-one" className="w-32 font-bold">
            For Element{" "}
          </span>
          <span
            id="tweak-app-span-two"
            className="w-auto font-bold text-blue-500 mr-2"
          >
            {selectedElementId}
          </span>
          <input
            type="text"
            placeholder="What changes would you like?"
            className="border border-gray-300 rounded-full py-2 px-4 w-full mr-4"
            value={request}
            id="tweak-app-input-one"
            onChange={(e) => {
              setRequest(e.target.value);
            }}
          />
          <button
            id="tweak-app-button-one"
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-full"
            onClick={() => {
              onClickSubmit(request);
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};
