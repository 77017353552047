import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IoEllipsisHorizontal } from "react-icons/io5";
import { useUserStateContext } from "../../provider/userStateProvider";
import { SignOutUser } from "../../firebaseModule/Auth";

// Wallet connect

export const NavbarWebsiteGenerator = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const context = useUserStateContext();
  const navigate = useNavigate();

  const navigateTo = () => {
    navigate("/", { replace: true });
  };
  const navigateToHomePage = () => {
    navigate("/home", { replace: true });
  };

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  });

  return (
    <nav
      className={`absolute h-[20px] pt-6  bg-transparent flex flex-row justify-between px-5 items-center overflow-visible z-10 w-screen pb-1`}
    >
      <div
        className=" h-10 w-14 text-2xl transition-colors lg:hidden ease-in-out bg-white hover:bg-gray-100 cursor-pointer flex items-center border-[1.5px] rounded-xl justify-center"
        onClick={() => setMenuOpen(true)}
      >
        <IoEllipsisHorizontal />
      </div>
      <Link to={"/"} className="outline-none">
        <div className="flex items-center gap-2 cursor-pointer">
          <span className="text-md font-semibold hidden sm:block text-[black] text-xl ">
            BroCode.ai
          </span>
        </div>
      </Link>

      <div className="flex flex-row justify-end">
        <button
          className="bg-black  text-white p-2 px-4 rounded-md ml-2"
          onClick={() => {
            if (context?.user) {
              SignOutUser(navigateTo);
            } else {
              context?.setIsOpenSignInModal(true);
            }
          }}
        >
          {context?.user ? "Sign out" : "Try it"}
        </button>
      </div>
    </nav>
  );
};
