import { Fragment, useState, useEffect } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { ILanguage } from "./ILanguageModal";
import { BiX } from "react-icons/bi";
import { useUserStateContext } from "../../../provider/userStateProvider";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import { SupportedLanguages } from "../../../enums/SupportedLanguages";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#ffffff",
    border: "1px solid #282828",
    fontSize: 16,
    padding: "5px 30px 5px 30px",
    color: "black",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#282828",
      backgroundColor: "#ffffff",
    },
  },
}));

export const LanguageModal = ({
  isOpen,
  setIsOpen,
  language,
  setLanguage,
  title,
  onSelectLanguage,
}: ILanguage) => {
  const [form, setForm] = useState({
    userName: "",
    email: "",
    accepted: false,
  });
  const [disabled, setDisabled] = useState(true);
  const context = useUserStateContext();

  useEffect(() => {
    if (
      form.userName.length > 0 &&
      form.email.length > 0 &&
      form.accepted === true
    )
      setDisabled(false);
    else setDisabled(true);
  }, [form]);

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const handleChange = (event: { target: { value: string } }) => {
    setLanguage(event.target.value);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md"></div>
        <div className="fixed inset-0 flex items-center justify-center sm:p-4">
          <div className="absolute text-2xl right-5 top-8 cursor-pointer hover:bg-slate-100 p-1 border-[1px] rounded-md">
            <BiX onClick={onCloseModal} />
          </div>

          <div className="flex h-full w-full sm:items-center items-end justify-center sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full border-[1px] sm:max-w-sm transform overflow-scroll sm:rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                <div className="flex flex-col gap-4 justify-center items-center">
                  <span className="text-lg font-medium">{title}</span>
                  <div className="flex flex-row justify-center items-center align-middle bg-white w-full">
                    <Select
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={language}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                    >
                      {SupportedLanguages?.map((language: string) => (
                        <MenuItem value={language}>{language}</MenuItem>
                      ))}
                    </Select>
                  </div>
                  <button
                    onClick={() => {
                      onSelectLanguage();
                    }}
                    className="h-10 text-sm border-[1px] border-black w-3/5 rounded-lg hover:bg-gray-50 flex flex-row justify-center items-center px-4 py-4"
                  >
                    Submit
                    <div />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
