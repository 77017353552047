import { Suspense, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Loader } from "../components/Loader";
import { Error } from "../pages/Error/Error";
import { Home } from "../pages/Home";
import { LandingPage } from "../pages/LandingPage";
import { useUserStateContext } from "../provider/userStateProvider";
import { ExitModal } from "../components/Modals/ExitModal";
import {
  CodeEditorLayout,
  LandingPageLayout,
  WebsiteGeneratorLayout,
} from "../layouts";
import { SignInModal } from "../components/Modals/SignInModal";
import { RequireAuth } from "../components/RequireAuth";
import { ContactUs } from "../pages/ContactUs";
import { GenerateWebsite } from "../pages/GenerateWebsite";
import { TweakApp } from "../pages/TweakApp";
import { NoAuthRequired } from "../components/NoAuthRequired";

const App = () => {
  const context = useUserStateContext();
  const [showExitForm, setShowExitForm] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", showFormHandler);

  //   return () => {
  //     window.removeEventListener("beforeunload", showFormHandler);
  //   };
  // }, [context]);

  return (
    <div className="min-h-screen relative font-raleway" id="app">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route element={<CodeEditorLayout />}>
              <Route element={<RequireAuth />}>
                <Route path="/devtool" element={<Home />} />
              </Route>
            </Route>
            <Route element={<LandingPageLayout />}>
              <Route element={<NoAuthRequired />}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<Error />} />
              </Route>
            </Route>
            <Route element={<WebsiteGeneratorLayout />}>
              <Route element={<RequireAuth />}>
                <Route path="/generateWebsite" element={<GenerateWebsite />} />
                <Route path="/tweak/:uid" element={<TweakApp />} />
              </Route>
            </Route>
          </Routes>
        </Suspense>
        <SignInModal
          isOpen={context?.isOpenSignInModal ?? false}
          setIsOpen={context?.setIsOpenSignInModal}
          onSuccess={context?.onSuccessSignInModal}
          onFailure={context?.onFailureSignInModal}
        />
      </BrowserRouter>
      <ExitModal
        isOpen={showExitForm}
        setIsOpen={setShowExitForm}
        isExitFormFilled={context?.user?.isExitFormFilled || false}
      />
    </div>
  );
};

export default App;
