export enum RequestEnums {
  REWRITE = "/rewrite/",
  REFACTOR = "/refactor/",
  MIGRATE = "/migrate/",
  SNIPPETS = "/snippets/",
  UNIT_TEST = "/writeUnitTests",
  IDENTIFY_BUGS = "/identifyBugs",
  OPTIMIZE_FOR_PERFORMANCE = "/optimizeForPerformance",
  GENERATE_WEBSITE = "/generateWebsite/generate",
  CHANGE_WEBSITE = "/qna",
}
