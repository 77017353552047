import { useNavigate } from "react-router-dom";
import { FireEvent } from "../../helpers/fireEvent";
import { Events } from "../../enums/Events";

export const Error = () => {
  FireEvent(Events.ViewErrorPage, {});
  const navigate = useNavigate();
  return (
    <div className="min-h-[70vh] w-screen flex flex-col gap-6 items-center justify-center">
      <h1 className="md:text-[13rem] text-6xl font-medium md:leading-tight">
        404
      </h1>
      <span className="md:text-lg text-sm font-medium">
        We can't find that page you're looking for
      </span>
      <div className="flex items-center gap-4 text-sm font-semibold">
        <button
          className="h-12 px-5 bg-black text-white rounded-xl"
          onClick={() => navigate("/")}
        >
          Take me home
        </button>
        <button
          className="h-12 px-5 bg-gray-200 rounded-xl hover:bg-gray-300"
          onClick={() => navigate("/faq")}
        >
          I need help
        </button>
      </div>
    </div>
  );
};
