import { Fragment } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import { Dialog, Transition } from "@headlessui/react";
import { IExitModal } from "./IExitModal";
import { BiX } from "react-icons/bi";
import { updateDocumentData } from "../../../firebaseModule/Queries";
import { useUserStateContext } from "../../../provider/userStateProvider";
import { COLLECTION_NAMES } from "../../../enums/CollectionNames";

interface FormData {
  rating: Number;
}

export const ExitModal = ({
  isOpen,
  setIsOpen,
  isExitFormFilled,
  setLoading,
}: IExitModal) => {
  const methods = useForm<FormData>();
  const context = useUserStateContext();
  const rating = methods?.watch("rating", 0);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    updateDocumentData(
      { isExitFormFilled: true, ratings: data.rating },
      COLLECTION_NAMES.USERS,
      context?.user?.uid ?? ""
    )
      .then(() => {
        setIsOpen(false);
      })
      .catch((e) => {
        console.log("some error", e);
      });
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md"></div>
        <div className="fixed inset-0 flex items-center justify-center sm:p-4">
          <div className="absolute text-2xl right-5 top-8 cursor-pointer hover:bg-slate-100 p-1 border-[1px] rounded-md">
            <BiX onClick={onCloseModal} />
          </div>

          <div className="flex h-full w-full sm:items-center items-end justify-center sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full border-[1px] sm:max-w-sm transform overflow-scroll sm:rounded-2xl bg-white py-6 px-0 text-left align-middle shadow-xl transition-all relative">
                <div className="flex flex-col gap-4 justify-center items-center">
                  <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    className="space-y-4"
                  >
                    <div className="text-xl font-bold">Rate our product</div>
                    <div className="flex">
                      {[1, 2, 3, 4, 5].map((value) => (
                        <label
                          key={value}
                          className="flex items-center cursor-pointer"
                        >
                          <input
                            type="radio"
                            name="rating"
                            value={value}
                            ref={methods.register as any}
                            checked={rating === value}
                            className="form-radio h-4 w-4 text-blue-600"
                          />
                          <span className="ml-2">{value}</span>
                        </label>
                      ))}
                    </div>
                    <button
                      type="submit"
                      className="bg-blue-600 text-white py-1 px-4 rounded"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
