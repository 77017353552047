import { Outlet } from "react-router-dom";
import { NavbarWebsiteGenerator } from "../components/NavBar/NavbarWebsiteGenerator";

export const WebsiteGeneratorLayout = () => {
  return (
    <>
      <NavbarWebsiteGenerator />
      <Outlet />
    </>
  );
};
