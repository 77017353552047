import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Components
import { RegistrationForm } from "../RegistrationForm";
import MenuModal from "./MenuModal";
import { PAGE_LINKS } from "../../enums/Navlinks";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { useUserStateContext } from "../../provider/userStateProvider";
import { SignOutUser } from "../../firebaseModule/Auth";

// Wallet connect

export const Navbar = () => {
  const [wallet, setWallet] = useState<any>("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const context = useUserStateContext();
  const navigate = useNavigate();

  window.addEventListener("scroll", () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  });
  const navigateTo = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="absolute ">
      <nav
        className={` h-[48px]  bg-transparent  flex flex-row justify-between px-5 items-center bg-[#282828] text-white  overflow-visible  z-10 w-screen `}
      >
        <div
          className="h-10 w-14 text-2xl transition-colors lg:hidden ease-in-out bg-white hover:bg-gray-100 cursor-pointer flex items-center border-[1.5px] rounded-xl justify-center"
          onClick={() => setMenuOpen(true)}
        >
          <IoEllipsisHorizontal />
        </div>
        <Link to={"/"} className="outline-none">
          <div className="flex items-center gap-2 cursor-pointer">
            <span className="text-md font-semibold hidden sm:block text-[white] text-xl ">
              BroCode.ai
            </span>
          </div>
        </Link>
        <div className="flex flex-row justify-start align-baseline">
          {PAGE_LINKS.map((link: any, index: number) => {
            return (
              <Link
                to={link.uri}
                className="outline-none mr-10 font-semibold text-lg"
                key={`${index + link.label}`}
              >
                <div className="flex items-center gap-2 cursor-pointer">
                  <span className="text-md font-semibold hidden sm:block text-[white] ">
                    {link.label}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
        <button
          className="bg-black  text-white p-2 px-4 rounded-md"
          onClick={() => {
            if (context?.user) {
              SignOutUser(navigateTo);
            } else {
              context?.setIsOpenSignInModal(true);
            }
          }}
        >
          {context?.user ? "Sign out" : "Try it"}
        </button>
      </nav>
      <MenuModal isOpen={menuOpen} closeModal={() => setMenuOpen(false)} />
      <RegistrationForm
        isOpen={formOpen}
        closeModal={() => setFormOpen(false)}
        disconnect={() => setWallet("")}
        wallet={wallet}
      />
    </div>
  );
};
