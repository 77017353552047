import { Fragment, useState } from "react";
import { Link } from "react-router-dom";

// Components
import { Dialog, Transition } from "@headlessui/react";

// Icons
import { BiX } from "react-icons/bi";

// Constants
import { PAGE_LINKS, SOCIAL_MEDIA_LINKS } from "../../enums/Navlinks";
// Types
import ModalProps from "../../types/ModalTypes";

const MenuModal = ({ isOpen, closeModal }: ModalProps) => {
  const [linkFocus, setLinkFocus] = useState<string | null>(null);
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md" />
        </Transition.Child>

        <div className="fixed inset-0 flex items-center justify-end sm:p-4">
          <div className="flex h-full w-full items-center justify-end sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full h-full max-w-sm transform overflow-scroll sm:rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                <BiX
                  className="absolute text-2xl right-5 top-8 cursor-pointer"
                  onClick={closeModal}
                />
                <div className="flex flex-col gap-10 h-full justify-between">
                  <div className="flex flex-col gap-3 flex-shrink-0 ">
                    {PAGE_LINKS.map((link) => (
                      <Link
                        className="outline-none"
                        to={link.useOnClick ? "" : link.uri}
                        onClick={() => {
                          link.useOnClick && link.onClick();
                          !link.useOnClick && setLinkFocus(null);
                          !link.useOnClick && closeModal();
                        }}
                        key={link.uri}
                      >
                        <span
                          className={`text-2xl font-semibold delay-75 py-4 ${
                            linkFocus === null
                              ? `text-black`
                              : linkFocus === link.uri
                              ? `text-black`
                              : `text-gray-400`
                          }`}
                          onMouseEnter={() => {
                            setLinkFocus(link.uri);
                          }}
                          onMouseLeave={() => {
                            setLinkFocus(null);
                          }}
                        >
                          {link.label}
                        </span>
                      </Link>
                    ))}
                  </div>
                  <div className="pt-4 px-4 border-t-[1px] flex justify-between items-center flex-shrink-0">
                    {SOCIAL_MEDIA_LINKS.map((link) => (
                      <div
                        key={link.alt}
                        className="text-gray-400 text-2xl cursor-pointer hover:text-black transition-colors"
                      >
                        <a
                          href={link.uri}
                          className="outline-none"
                          target="_blank"
                          onClick={closeModal}
                          rel="noreferrer"
                        >
                          <link.icon />
                        </a>
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MenuModal;
