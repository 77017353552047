import { useForm } from "react-hook-form";

export default function SubscriptionForm() {
  const { register, handleSubmit } = useForm();
  const onSubmit = handleSubmit((data) => data);
  return (
    <form
      onSubmit={onSubmit}
      className="flex flex-row items-center relative h-[80px] text-[15px]"
    >
      <input
        {...register("email")}
        placeholder="Your email"
        type="email"
        className="bg-[#191C1F] bg-opacity-4 absolute h-[55px] rounded-[14px] p-[19px] w-full  focus:ring focus:ring-gray-100/[4] focus:outline-none focus:bg-white "
        required
      />
      <button
        type="submit"
        className="btn h-[44px] absolute right-1.5 rounded-[14px] bg-black px-4 text-white"
      >
        {" "}
        I'm in!
      </button>
    </form>
  );
}
