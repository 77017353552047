import { PROBLEMS } from "../../../enums/ProblemsConstants";

export const Problems = () => {
  const renderProblemCards = () => {
    if (!PROBLEMS || PROBLEMS.length === 0) {
      return <p>No problems found.</p>;
    }

    return PROBLEMS.map((problem, index) => {
      const problemTitle = problem.title || "Untitled Problem";
      const problemDescription =
        problem.description || "No description available";
      const problemIcon = problem.icon || (() => <div />);

      return (
        <div
          className="bg-gray-100 hover:bg-white text-black rounded-lg p-5 flex flex-col justify-start gap-4 lg:h-40"
          key={`${index + problemTitle}`}
        >
          <div className="flex flex-row justify-start items-start">
            <div className="w-16">{problemIcon()}</div>
            <div className="flex flex-col justify-start items-start ml-3">
              <h3 className="text-xl">{problemTitle}</h3>
              <p className="text-md mt-4">{problemDescription}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bg-black text-white flex flex-col h-auto w-screen justify-center items-center py-10 md:py-16 px-2 md:px-44 mb-12">
      <h1 className="text-3xl mb-4 md:mb-8">
        Problems with WebApp Development
      </h1>
      <div className="grid lg:grid-cols-3 gap-4">{renderProblemCards()}</div>
    </div>
  );
};
