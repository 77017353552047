import { useUserStateContext } from "../../provider/userStateProvider";
import { Outlet } from "react-router-dom";

export const RequireAuth = () => {
  let context = useUserStateContext();

  if (!context?.isAuthenticated) {
    context?.setIsOpenSignInModal && context.setIsOpenSignInModal(true);
  }
  if (context?.isAuthenticated) {
    context?.setIsOpenSignInModal && context.setIsOpenSignInModal(false);
  }

  return <Outlet />;
};
