import { useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
import { GenerateWebsiteAPI } from "../../apis/generateWebsiteApi";
import { useUserStateContext } from "../../provider/userStateProvider";
import { LoadingIndicator } from "../LoadingIndicator";
import { SearchField } from "../../components/SearchField";

export const BillBoard = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useUserStateContext();

  const navigate = useNavigate();

  const onClickGenerate = async (searchString: string) => {
    if (!context?.isAuthenticated) {
      context?.setIsOpenSignInModal(true);
      return;
    }
    setIsLoading(true);
    GenerateWebsiteAPI(
      searchString,
      context?.user?.uid ?? "",
      context?.user?.userToken ?? ""
    )
      .then((data) => {
        navigate(`/tweak/${context?.user?.uid}`, { replace: true });
        // setResult(data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div
      className="w-full flex flex-col md:flex-row justify-center items-center mb-10 md:mb-0 relative"
      style={{ height: "100vh" }}
      id="metastart"
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        className="absolute top-0 left-0 z-0 w-full h-full object-cover bg-black"
        src={
          "https://themes.fourthwall.com/themes/assets/11d2f253-34b7-4fdc-b01b-17868cd74972/assets/video-bg-light.mp4?v=ab4ceb1e95742187fe677bf9070cf092638955e28eec6d5b87a3db7455d9948b"
        }
      />
      <div className="flex flex-col justify-center items-center w-2/3 mt-24 md:mt-0">
        <h1 className="font-raleway text-5xl md:text-7xl z-10">{`<broCODE.ai>`}</h1>
        {/* <div className="z-0 flex flex-col md:flex-row justify-center items-baseline w-full mt-3 text-center">
          <span className="text-md md:text-lg">
            No code
            <span className="font-raleway text-xl md:text-2xl mx-2 text-blue-500">
              <Typewriter
                words={["ever", "ever"]}
                loop={5}
                cursor
                cursorStyle="_"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
          </span>
        </div> */}
        <SearchField onClickGenerate={onClickGenerate} />

        {/* <button
            className="bg-black text-white py-2 px-4 rounded-md text-xl font-semibold hover:bg-blue-600 z-10"
            onClick={() => onClickGenerateWebsite()}
          >
            Generate Website
          </button>
          <button
            className="bg-black text-white py-2 px-4 rounded-md text-xl font-semibold hover:bg-blue-600 z-10"
            onClick={() => onClickDevTool()}
          >
            Fix your code
          </button> */}
      </div>
    </div>
  );
};
