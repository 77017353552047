import { useUserStateContext } from "../../provider/userStateProvider";
import { Outlet } from "react-router-dom";

export const NoAuthRequired = () => {
  let context = useUserStateContext();

  //TODO: This logic needs to be enhanced
  context?.setIsOpenSignInModal &&
    !context?.isOpenSignInModal &&
    context.setIsOpenSignInModal(false);

  return <Outlet />;
};
