import { useState } from "react";

export interface ISearchField {
  onClickGenerate: (searchString: string) => void;
}

export const SearchField = ({ onClickGenerate }: ISearchField) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const validateInput = (input: string) => {
    return input.trim() !== "" && isNaN(Number(input));
  };

  const handleButtonClick = () => {
    if (validateInput(searchTerm)) {
      onClickGenerate(searchTerm);
    }
  };

  return (
    <div className="flex flex-col justify-start items-center w-full z-10 ">
      <div className="relative text-gray-600 focus-within:text-gray-400 w-4/5 md:w-2/3 mt-10">
        <span className="absolute inset-y-0 left-0 flex items-center pl-2">
          <button
            type="submit"
            className="p-1 focus:outline-none focus:shadow-outline"
          >
            <svg
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 15l-5-5 5-5"
              />
            </svg>
          </button>
        </span>
        <input
          type="search"
          name="q"
          className="py-2 text-sm text-white bg-gray-900 rounded-md pl-10 focus:outline-none focus:bg-white focus:border-2 focus:border-black focus:text-gray-900 w-full h-12"
          placeholder="What type of website would you like?"
          autoComplete="off"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <button
        className={`bg-gray-900 text-white font-bold py-2 px-4 rounded-md mt-6 ${
          !validateInput(searchTerm) ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={handleButtonClick}
        disabled={!validateInput(searchTerm)}
      >
        Generate
      </button>
    </div>
  );
};
