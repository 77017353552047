import { Fragment } from "react";
import {
  useForm,
  SubmitHandler,
  FormProvider,
  Controller,
} from "react-hook-form";

import { Dialog, Transition } from "@headlessui/react";
import { INewUserRegistration } from "./INewUserRegistration";
import { BiX } from "react-icons/bi";
import { InputField } from "../../InputField/InputField";
import { updateDocumentData } from "../../../firebaseModule/Queries";
import { useUserStateContext } from "../../../provider/userStateProvider";
import { COLLECTION_NAMES } from "../../../enums/CollectionNames";

interface FormData {
  experience: string;
  username: string;
  designation: string;
  purpose: string;
}

const options = [
  { label: "0", value: "0" },
  { label: "+1", value: "+1" },
  { label: "+3", value: "+3" },
  { label: "+5", value: "+5" },
  { label: "+10", value: "+10" },
];

export const NewUserRegistration = ({
  isOpen,
  setIsOpen,
  isUserRegistered,
  setLoading,
}: INewUserRegistration) => {
  const methods = useForm<FormData>();
  const context = useUserStateContext();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setLoading(true);
    updateDocumentData(
      { registrationDetails: data, isRegistered: true },
      COLLECTION_NAMES.USERS,
      context?.user?.uid ?? ""
    )
      .then(() => {
        setLoading(false);
        setIsOpen(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onCloseModal = () => {
    if (isUserRegistered) {
      setIsOpen(false);
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCloseModal}>
        <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md"></div>
        <div className="fixed inset-0 flex items-center justify-center sm:p-4">
          <div className="absolute text-2xl right-5 top-8 cursor-pointer hover:bg-slate-100 p-1 border-[1px] rounded-md">
            <BiX onClick={onCloseModal} />
          </div>

          <div className="flex h-full w-full sm:items-center items-end justify-center sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full border-[1px] sm:max-w-sm transform overflow-scroll sm:rounded-2xl bg-white py-6 px-0 text-left align-middle shadow-xl transition-all relative">
                <FormProvider {...methods}>
                  <div className="flex flex-col gap-4 justify-center items-center">
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                      <span className="text-2xl font-medium">Hey there!</span>
                      <InputField name="username" label="Name:" />
                      <label className="">Experience: </label>
                      <div className="flex flex-row justify-start items-start mt-2">
                        {options.map((option) => (
                          <div key={option.value} className="mr-6 ">
                            <Controller
                              name="experience"
                              control={methods.control}
                              defaultValue=""
                              rules={{ required: true }}
                              render={({ field: { onChange, value } }) => (
                                <label className="inline-flex items-center">
                                  <input
                                    type="radio"
                                    value={option.value}
                                    onChange={onChange}
                                    checked={value === option.value}
                                    className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                                  />
                                  <span className="ml-1 text-gray-700">
                                    {option.label}
                                  </span>
                                </label>
                              )}
                            />
                          </div>
                        ))}
                      </div>
                      <InputField name="designation" label="Designation: " />
                      <InputField
                        name="purpose"
                        label="Purpose of using this tool: "
                      />

                      <button className="h-10 text-sm  border-[2px] border-black w-full rounded-lg hover:bg-gray-50 flex flex-row justify-center items-center px-4 py-4 mt-10 font-bold">
                        SUBMIT
                        <div />
                      </button>
                    </form>
                  </div>
                </FormProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
