import { useState, useMemo } from "react";
import CodeEditor from "@uiw/react-textarea-code-editor";
import { SupportedLanguages } from "../../enums/SupportedLanguages";
import { useUserStateContext } from "../../provider/userStateProvider";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { LanguageModal } from "../../components/Modals/LanguageModal";
import { Events } from "../../enums/Events";
import { FireEvent } from "../../helpers/fireEvent";
import { NewUserRegistration } from "../../components/Modals/NewUserRegistrationModal";

import {
  identifyBugs,
  writeUnitTests,
  optimizeForPerformance,
  refactor,
  migrateCode,
} from "../../apis";

export const Home = () => {
  const context = useUserStateContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isLanguageModalOpen, setIsLanguageModalOpen] =
    useState<boolean>(false);
  const [result, setResult] = useState<string>("");
  const [isRegistrationFormOpen, setIsRegistrationFormOpen] =
    useState<boolean>(true);

  const [code, setCode] = useState<string>(
    `function add(a, b) {\n  return a + b;\n}`
  );

  FireEvent(Events.ViewHomePage, {});

  const [language, setLanguage] = useState<string>(SupportedLanguages[0]);

  const onClickOptimizeForPerformance = async () => {
    setLoading(true);
    optimizeForPerformance(code, language, context?.user?.userToken ?? "")
      .then((data) => {
        setResult(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onClickWriteUnitTests = async () => {
    setLoading(true);
    writeUnitTests(code, language, context?.user?.userToken ?? "")
      .then((data) => {
        setResult(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onClickRefactor = async () => {
    setLoading(true);
    refactor(code, language, context?.user?.userToken ?? "")
      .then((data) => {
        setResult(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onClickIdentifyBugs = async () => {
    setLoading(true);
    identifyBugs(code, language, context?.user?.userToken ?? "")
      .then((data) => {
        setResult(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onClickMigrateCode = async () => {
    setIsLanguageModalOpen(true);

    // setLoading(true);
  };
  const onSelectLanguage = async () => {
    setIsLanguageModalOpen(false);
    setLoading(true);
    migrateCode(code, "javascript", language, context?.user?.userToken ?? "")
      .then((data) => {
        setResult(data.data);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useMemo(() => {
    if (!context?.isAuthenticated) {
      context?.setIsOpenSignInModal(true);
    } else {
      context?.setIsOpenSignInModal(false);
    }
  }, [context?.isAuthenticated]);

  useMemo(() => {
    if (!context?.user?.isRegistered) {
      setIsRegistrationFormOpen(true);
    } else {
      setIsRegistrationFormOpen(false);
    }
  }, [context?.user?.isRegistered]);

  return (
    <div className="h-screen pt-12 w-screen flex flex-row justify-between bg-[#282828] text-white">
      <div className="w-4/5 pl-10 pt-10 pr-10 border-t-[3rem] border-[#1A1A1A]">
        <h2 className="text-3xl">
          Just paste your code and see the magic happen!
        </h2>
        <p className="text-sm my-6">
          Paste your code and we will fix all the bugs, improve the code for
          security flaws and and optimize it for performance. And Guess what
          also give you all the unit tests for your 100% test coverage
        </p>
        <div className=" flex flex-col pr-12">
          <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              onClickOptimizeForPerformance();
            }}
          >
            Optimize for Performance
          </button>
          <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              onClickWriteUnitTests();
            }}
          >
            Write Unit tests
          </button>
          <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              onClickRefactor();
            }}
          >
            Refactor
          </button>
          <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              onClickIdentifyBugs();
            }}
          >
            Identify all bugs
          </button>
          <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              onClickMigrateCode();
            }}
          >
            Migrate
          </button>
          {/* <button
            className="bg-[#4CC475] rounded-md flex justify-center align-middle text-sm p-2 font-bold my-2"
            onClick={() => {
              navigate("/github", {
                replace: true,
              });
            }}
          >
            Link your Github Repository
          </button> */}
        </div>
      </div>
      <div className="flex flex-col border-8 border-[#1A1A1A] border-t-[2.85rem]">
        <CodeEditor
          value={code}
          language="js"
          placeholder="Please enter JS code."
          onChange={(evn) => setCode(evn.target.value)}
          padding={15}
          style={{
            fontSize: 12,
            backgroundColor: "#282828",
            fontFamily:
              "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
            overflow: "scroll",
            height: "22.5rem",
            width: "60rem",
            borderTop: "2px solid #1A1A1A",
          }}
        />

        <div className=" h-full">
          <h3 className="w-full border-t-8 bg-[#1A1A1A] border-[#1A1A1A] text-white pl-3">
            Output:
          </h3>
          <textarea
            className=" h-full w-1/2 border-t-8 border-[#1A1A1A] bg-gray-300 pl-3 pt-3"
            style={{
              fontSize: 12,
              backgroundColor: "#282828",
              fontFamily:
                "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
              overflow: "scroll",
              height: "22.5rem",
              width: "60rem",
            }}
            value={result}
          />
        </div>
      </div>
      {isLoading && <LoadingIndicator />}
      <LanguageModal
        isOpen={isLanguageModalOpen}
        setIsOpen={setIsLanguageModalOpen}
        language={language}
        setLanguage={setLanguage}
        title={"Choose a language to you to migrate to"}
        onSelectLanguage={onSelectLanguage}
      />
      <NewUserRegistration
        isOpen={isRegistrationFormOpen}
        setIsOpen={setIsRegistrationFormOpen}
        isUserRegistered={context?.user?.isRegistered ?? false}
        setLoading={setLoading}
      />
    </div>
  );
};
