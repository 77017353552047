import { Fragment, useEffect, useState } from "react";

// Components
import { Dialog, Transition } from "@headlessui/react";

// Icons
import { BiX } from "react-icons/bi";

// Types
import RegistrationModalProps from "../../types/registrationModalTypes";

export const RegistrationForm = ({
  isOpen,
  closeModal,
  disconnect,
}: RegistrationModalProps) => {
  const [form, setForm] = useState({
    userName: "",
    email: "",
    accepted: false,
  });

  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (
      form.userName.length > 0 &&
      form.email.length > 0 &&
      form.accepted === true
    )
      setDisabled(false);
    else setDisabled(true);
  }, [form]);

  const handleFinish = () => {
    closeModal();
    setForm({ userName: "", email: "", accepted: false });
  };

  const handleDisconnect = () => {
    disconnect();
    closeModal();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40 " onClose={closeModal}>
        <div className="fixed inset-0 bg-white bg-opacity-50 backdrop-blur-md"></div>
        <div className="fixed inset-0 flex items-center justify-center sm:p-4">
          <div className="absolute text-2xl right-5 top-8 cursor-pointer hover:bg-slate-100 p-1 border-[1px] rounded-md">
            <BiX onClick={closeModal} />
          </div>

          <div className="flex h-full w-full sm:items-center items-end justify-center sm:p-6">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full border-[1px] sm:max-w-sm transform overflow-scroll sm:rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all relative">
                <div className="flex flex-col gap-4 justify-center items-center">
                  <span className="text-lg font-medium">
                    You are almost there!
                  </span>
                  <span className="text-xs text-gray-500 text-center">
                    Choose a display name and enter your email address to
                    receive updates when your NFTs sell or receive offers.
                  </span>
                  <input
                    type="text"
                    className="w-full h-12 focus:outline-none border-[1px] rounded-lg px-2 text-sm"
                    placeholder="Display name"
                    value={form.userName}
                    onChange={(e) =>
                      setForm({ ...form, userName: e.target.value })
                    }
                  />
                  <input
                    type="email"
                    className="w-full h-12 focus:outline-none border-[1px] rounded-lg px-2 text-sm"
                    placeholder="Enter your email"
                    value={form.email}
                    onChange={(e) =>
                      setForm({ ...form, email: e.target.value })
                    }
                  />
                  <div className="flex gap-2 items-start">
                    <input
                      type="checkbox"
                      className="mt-0.5 cursor-pointer"
                      checked={form.accepted}
                      onChange={() =>
                        setForm({ ...form, accepted: !form.accepted })
                      }
                    />
                    <span className="text-xs text-gray-500">
                      I have read and accepted the{" "}
                      <a href="/terms" className="text-black">
                        Terms of Service
                      </a>{" "}
                      and confirm that I am at least 13 years old.
                    </span>
                  </div>
                  <button
                    className={`h-10 text-sm border-[1px] w-full rounded-lg ${
                      disabled === false
                        ? "hover:bg-gray-50"
                        : "cursor-not-allowed bg-gray-100 text-gray-500"
                    }`}
                    disabled={disabled}
                    onClick={handleFinish}
                  >
                    Finish sign-up
                  </button>
                  <button
                    onClick={handleDisconnect}
                    className="h-10 text-sm border-[1px] w-full rounded-lg hover:bg-gray-50"
                  >
                    Disconnect
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
