import { BsPaintBucket } from "react-icons/bs";
import { GiSplashyStream, GiWeightLiftingUp } from "react-icons/gi";
import { FaRegHandPeace } from "react-icons/fa";
import { MdAttachMoney, MdOutlinePublishedWithChanges } from "react-icons/md";

export const SOLUTIONS = [
  {
    icon: <FaRegHandPeace size={50} />,
    title: "Simplified website creation",
    description:
      "BroCode can handle the technical aspects of app/webApp development, enabling users to create websites without extensive knowledge of programming languages or low code no code tools.",
  },
  {
    icon: <BsPaintBucket size={50} />,
    title: "Intelligent UI/UX design",
    description:
      "BroCode can generate visually appealing and user-friendly designs, tailored to the user's preferences and requirements, without requiring design expertise from the user.",
  },
  {
    icon: <GiSplashyStream size={50} />,
    title: "Automated SEO and performance optimization",
    description:
      "BroCode can analyze and optimize the website's content and structure for search engines, improving visibility and user experience without requiring manual intervention from the user.",
  },
  {
    icon: <GiWeightLiftingUp size={50} />,
    title: "Streamlined maintenance and updates",
    description:
      "BroCode can automate website maintenance tasks, such as updating content, fixing bugs, or optimizing performance, ensuring the website remains up-to-date and functional.",
  },
  {
    icon: <MdAttachMoney size={50} />,
    title: "Cost-effective solution",
    description:
      "BroCode can be more affordable than hiring professional web developers or designers, making it accessible to small businesses and individuals with budget constraints.",
  },
  {
    icon: <MdOutlinePublishedWithChanges size={50} />,
    title: "Customization and flexibility",
    description:
      "BroCode can offer a wide range of customization options, allowing users to create unique and personalized websites while still benefiting from the simplicity of no-code platforms.",
  },
];
