import { BsDiscord, BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";

export const PAGE_LINKS = [
  // {
  //   label: "How it works",
  //   uri: "/faq",
  //   useOnClick: false,
  //   onClick: () => {},
  // },
  // {
  //   label: "Blog",
  //   uri: "/blog",
  //   useOnClick: false,
  //   onClick: () => {},
  // },
  {
    label: "Contact us",
    uri: "/contact-us",
    useOnClick: false,
    onClick: () => {},
  },
  {
    label: "Dev Tool",
    uri: "/devtool",
    useOnClick: false,
    onClick: () => {},
  },
];

export const SOCIAL_MEDIA_LINKS = [
  {
    alt: "twitter",
    icon: BsTwitter,
    uri: "https://twitter.com/metastartHQ",
  },
  {
    alt: "instagram",
    icon: BsInstagram,
    uri: "https://www.instagram.com/metastarthq/",
  },
  {
    alt: "discord",
    icon: BsDiscord,
    uri: "https://discord.com/invite/MrfH39DAu4",
  },
  {
    alt: "youtube",
    icon: BsYoutube,
    uri: "https://www.youtube.com/channel/UCQPbVOh_kh6SbZf9cJ3vrsA",
  },
];
