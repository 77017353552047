import { db } from "./FirebaseSetup";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  setDoc,
  orderBy,
  limit,
  getDoc,
  DocumentData,
  DocumentSnapshot,
} from "firebase/firestore";
import { IUser } from "../types/IUser";

export const allDocuments = (collectionName: any) => {
  return getDocs(collection(db, collectionName));
};
export const allDocumentsWithLimit = (
  collectionName: any,
  order_by_field: any,
  limit_number: any
) => {
  return getDocs(collection(db, collectionName));
};

export const specificDocumentsUsingWhere = (
  collectionName: any,
  docKey: any,
  docValue: any
) => {
  let x;
  try {
    x = getDocs(
      query(collection(db, collectionName), where(docKey, "==", docValue))
    );
  } catch (e) {
    console.log(e);
  }
  return x;
};

export const specificDocumentsUsingIn = (
  collectionName: any,
  docKey: any,
  docValueArray: any
) => {
  return getDocs(
    query(collection(db, collectionName), where(docKey, "in", docValueArray))
  );
};
export async function getFirestoreDocumentById(
  docId: string,
  collectionName: string
): Promise<DocumentData> {
  const docRef = doc(db, collectionName, docId.toString());
  const docSnap: DocumentSnapshot<DocumentData> = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    throw new Error("Document not found");
  }
}

export const updateDocumentData = (
  updateObj: any,
  collectionName: any,
  field_name: any
) => {
  return updateDoc(doc(db, collectionName, field_name), {
    ...updateObj,
  });
};

export const setNewDocument = (
  collectioName: any,
  doc_id: any,
  doc_object: any
) => {
  return setDoc(doc(db, `/${collectioName}/${doc_id}`), { ...doc_object });
};

export const fetchFirebaseAccount = (
  authId: any,
  userAuth: any,
  setUser: any
) => {
  specificDocumentsUsingWhere("users", "authId", authId)?.then((snapshot) => {
    if (snapshot.empty) {
      const { displayName, email } = userAuth;
      let userObject: IUser = { email: "", username: "", uid: "" };
      userObject["email"] = email;
      userObject["username"] = displayName;
      userObject["uid"] = userAuth.uid;
      userObject["dateOfJoining"] = Date.now();
      setDoc(doc(db, "users", userAuth.uid), {
        ...userObject,
      });
      setUser({
        ...userObject,
      });
    } else {
      snapshot.forEach((doc) => setUser(doc.data()));
    }
  });
};
