import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./routes/App";
import { UserStateProvider } from "./provider/userStateProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
const container: Element | DocumentFragment = document?.getElementById(
  "root"
) as Element;

const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <UserStateProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </UserStateProvider>
  </React.StrictMode>
);
