import { RequestEnums } from "../enums/RequestEnums";

export const optimizeForPerformance = async (
  code: any,
  from: any,
  userToken: string
) => {
  const data = await fetch(
    `${
      process.env.REACT_APP_API_URL +
      "/v1" +
      RequestEnums.OPTIMIZE_FOR_PERFORMANCE
    }`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify({ code, from }),
    }
  )
    .then((res) => res.json())
    .then((data) => data);
  return data;
};
