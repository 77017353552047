import { IInput } from "./IInputField";
import { useFormContext } from "react-hook-form";

export const InputField = ({ name, label }: IInput) => {
  const { register } = useFormContext();
  return (
    <div className=" flex flex-col justify-start items-start w-full mt-4 mb-4">
      <label className="font-raleway text-black text-md font-semibold">
        {label}
      </label>
      <div className="relative text-gray-600 focus-within:text-gray-400 w-full mt-2">
        <input
          type="text"
          className="py-2 text-sm text-black border-[1px] border-black rounded-md pl-2 focus:outline-none focus:border-2 focus:border-black w-full h-12"
          autoComplete="off"
          {...register(name, { required: true })}
        />
      </div>
    </div>
  );
};
