import { RequestEnums } from "../enums/RequestEnums";

export const GenerateWebsiteAPI = async (
  searchString: string,
  uid: string,
  userToken: string
) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_URL + "/v1" + RequestEnums.GENERATE_WEBSITE}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify({ uid, searchString }),
    }
  )
    .then((res) => res.json())
    .then((data) => data);
  return data;
};
