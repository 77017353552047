import { BsFillEmojiExpressionlessFill, BsScrewdriver } from "react-icons/bs";
import { BiPaint, BiTime } from "react-icons/bi";
import { MdAttachMoney } from "react-icons/md";
import { SiDependabot } from "react-icons/si";

import { GrDocumentPerformance } from "react-icons/gr";
export const PROBLEMS = [
  {
    description: "Limited technical knowledge hinders website creation.",
    title: "Limited technical knowledge",
    icon: () => <BsFillEmojiExpressionlessFill size={50} />,
    onClick: () => {},
  },
  {
    description: "Inexperience in UI/UX design affects app's appeal.",
    title: "Design challenges",
    onClick: () => {},
    icon: () => <BiPaint size={50} />,
  },
  {
    description: "Learning app development is time-consuming.",
    title: "Time-consuming learning process",
    onClick: () => {},
    icon: () => <BiTime size={50} />,
  },
  {
    description: "Lack of SEO and performance optimization knowledge.",
    title: "SEO and performance optimization",
    onClick: () => {},
    icon: () => <GrDocumentPerformance size={50} />,
  },
  {
    description: "Difficulty in website maintenance and updates.",
    title: "Maintenance",
    onClick: () => {},
    icon: () => <BsScrewdriver size={50} />,
  },
  {
    description: "Cost constraints when hiring professionals.",
    title: "Cost",
    onClick: () => {},
    icon: () => <MdAttachMoney size={50} />,
  },
  {
    description: "Dependence on website builders limits customization.",
    title: "Dependence on website builders",
    onClick: () => {},
    icon: () => <SiDependabot size={50} />,
  },
];
