import { BillBoard } from "../../components/Billboard";
import { Problems } from "../../components/Sections/Problems";
import { Solutions } from "../../components/Sections/Solutions";
import { FireEvent } from "../../helpers/fireEvent";
import { Events } from "../../enums/Events";

export const LandingPage = () => {
  FireEvent(Events.ViewLandingPage, {});
  return (
    <div className="h-auto w-screen font-raleway font-bold">
      <BillBoard />
      <Problems />
      <Solutions />
    </div>
  );
};
