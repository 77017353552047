import { RequestEnums } from "../enums/RequestEnums";
import { updateDocumentData } from "../firebaseModule/Queries";

export const ChangeApp = async (
  searchString: string,
  request: string,
  uid: string,
  userToken: string,
  chatHistory: string[],
  elementId: string,
  setIsLoading: (value: React.SetStateAction<boolean>) => void
) => {
  setIsLoading(true);

  try {
    await fetch(
      `${process.env.REACT_APP_API_URL + "/v1" + RequestEnums.CHANGE_WEBSITE}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken,
        },
        body: JSON.stringify({
          uid,
          question: searchString,
          chatHistory,
          elementId,
        }),
      }
    );

    const oldChatHistory = chatHistory ?? [];

    const newChatHistory = [
      ...oldChatHistory,
      `Rewrite the whole files where the code change is required according to the query: ${request}. rewrite the entire file with code changes. return in the format ^filename~code^filename`,
    ];

    await updateDocumentData(
      { chatHistory: newChatHistory },
      "users",
      uid ?? ""
    );

    setIsLoading(false);
    window.location.reload();

    return "Successful";
  } catch (error) {
    setIsLoading(false);
    console.error(error);
    throw new Error("Internal Server Error");
  }
};
