import SolutionsImage from "../../../assets/Images/solutions.png";
import { SOLUTIONS } from "../../../enums/Solutions";

export const Solutions = () => {
  const renderSolutionItems = () =>
    SOLUTIONS.map((solution, index) => (
      <div className="flex flex-col gap-1" key={`${solution.title}-${index}`}>
        <div className="flex items-center gap-2">
          {solution.icon}
          <p className="text-2xl lg:text-3xl">{solution.title}</p>
        </div>
        <p className="font-light">{solution.description}</p>
      </div>
    ));

  return (
    <div className="px-5 lg:px-14 py-10 container max-w-[1280px] mx-auto">
      <h2 className="text-xl lg:text-3xl mb-10">
        The Solution: {`${"<broCode.ai>"}`}
      </h2>
      <div className="flex items-center w-full">
        <div className="flex flex-col gap-12 pl-8 border-l-2 border-l-black lg:basis-1/2">
          {renderSolutionItems()}
        </div>
        <div className="basis-1/2 lg:flex items-center justify-center hidden">
          <img
            src={SolutionsImage}
            alt=""
            className="h-[40rem] absolute -mt-10"
          />
        </div>
      </div>
    </div>
  );
};
