export const LoadingIndicator = () => {
  return (
    <div className=" absolute top-0 left-0 w-screen h-screen flex items-center justify-center ">
      <div className="fixed inset-0 bg-white bg-opacity-100 backdrop-blur-md"></div>

      <div className="animate-pulse">
        <svg
          className="w-20 h-20 text-gray-300"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="6" x2="12" y2="12"></line>
          <line x1="12" y1="12" x2="16" y2="16"></line>
          <line x1="12" y1="12" x2="8" y2="16"></line>
        </svg>
      </div>
    </div>
  );
};
