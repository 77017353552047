import { IUser } from "./../types/IUser";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import "firebase/compat/auth";

export const provider = new GoogleAuthProvider();
export const auth = getAuth();
export const SignInWithGoogleProvider = (setUser: any, navigateTo: any) => {
  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      const user = result.user;
      let userObject: IUser = {
        username: "",
        profile_picture_url: "",
        dateOfJoining: Date.now(),
        emailVerified: user.emailVerified,
        email: user?.email ?? "",
        uid: user?.uid,
      };

      setUser(userObject);
      navigateTo();
      // ...
    })
    .catch((error) => {
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });
};

export const SignOutUser = (navigateTo: any) => {
  signOut(auth)
    .then(() => {
      navigateTo();
    })
    .catch((error) => {});
};
