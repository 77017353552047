import UserStateContext from "./context";
import { useState, useContext, useEffect } from "react";
import { specificDocumentsUsingWhere } from "../firebaseModule/Queries";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebaseModule/FirebaseSetup";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { SignOutUser } from "../firebaseModule/Auth";
import "firebase/compat/auth";
import { IUser } from "../types/IUser";
import { IUserStateContextProvider } from "./IUserStateContext";

export const UserStateProvider = ({
  children,
}: IUserStateContextProvider): JSX.Element => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isLightTheme, setLightTheme] = useState(true);
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false);
  const [onSuccessSignInModal, setOnSuccessSignInModal] = useState(() => {});
  const [onFailureSignInModal, setOnFailureSignInModal] = useState(() => {});

  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [errorModalMessage, setErrorModalMessage] = useState("Error");
  const [errorModalTitle, setErrorModalTitle] = useState(
    "Something went wrong!"
  );
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  const [isTermsAndConditionsModalOpen, setIsTermsAndConditionsModalOpen] =
    useState(false);

  const fetchFirebaseAccount = (authId: any, userAuth: any, token: any) => {
    specificDocumentsUsingWhere("users", "uid", authId)?.then((snapshot) => {
      let user: IUser = {
        username: "",
        profile_picture_url: "",
        dateOfJoining: Date.now(),
        emailVerified: userAuth.emailVerified,
        email: "",
        uid: "",
        userToken: "",
        isRegistered: false,
        isExitFormFilled: false,
        ratings: 0,
      };
      if (snapshot.empty) {
        user["email"] = userAuth.email;
        user["uid"] = userAuth.uid;
        user["emailVerified"] = userAuth.emailVerified;
        user["userToken"] = token;
        user["chatHistory"] = [];
        user["isRegistered"] = false;
        user["registrationDetails"] = {
          name: "",
          designation: "",
          experience: "",
          purpose: "",
        };
        user["isExitFormFilled"] = false;
        user["ratings"] = 0;
        setDoc(doc(db, "users", userAuth.uid), user).then(() => {
          setUser(user);
        });
      } else {
        user["email"] = userAuth?.email;
        user["uid"] = userAuth?.uid;
        user["emailVerified"] = userAuth.emailVerified;
        user["userToken"] = token;
        user["chatHistory"] = snapshot.docs[0].data().chatHistory;
        user["isRegistered"] = snapshot.docs[0].data()?.isRegistered || false;
        user["registrationDetails"] =
          snapshot.docs[0].data()?.registrationDetails || {};
        user["isExitFormFilled"] =
          snapshot.docs[0].data().isExitFormFilled || false;
        user["ratings"] = snapshot.docs[0].data().ratings || 0;
        setUser(user);
      }
    });
  };
  useEffect(() => {
    const auth = getAuth();

    if (auth) {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          try {
            user.getIdToken(true).then((token) => {
              process.env.NODE_ENV === "development" &&
                console.log("userToken", token, "user.uid", user.uid);
              setIsAuthenticated(true);
              fetchFirebaseAccount(user.uid, user, token);
            });
          } catch (error) {
            console.error(error);
            SignOutUser(() => {});
            setIsAuthenticated(false);
            setUser(null);
          }
        } else {
          setIsAuthenticated(false);
          setUser(null);
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, []);

  return (
    <UserStateContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
        isLightTheme,
        setLightTheme,
        isOpenSignInModal,
        setIsOpenSignInModal,
        onSuccessSignInModal,
        setOnSuccessSignInModal,
        onFailureSignInModal,
        setOnFailureSignInModal,
        isOpenErrorModal,
        setIsOpenErrorModal,
        errorModalMessage,
        setErrorModalMessage,
        errorModalTitle,
        setErrorModalTitle,
        isTermsAndConditionsModalOpen,
        setIsTermsAndConditionsModalOpen,
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
};
export const useUserStateContext = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("Encountered error!");
  }
  return context;
};
