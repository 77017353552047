import { RequestEnums } from "../enums/RequestEnums";

export const writeUnitTests = async (
  code: any,
  from: any,
  userToken: string
) => {
  const data = await fetch(
    `${process.env.REACT_APP_API_URL + "/v1" + RequestEnums.UNIT_TEST}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken,
      },
      body: JSON.stringify({ code, from }),
    }
  )
    .then((res) => res.json())
    .then((data) => data);
  return data;
};
