import { SearchField } from "../../components/SearchField";
import { useState } from "react";
import { GenerateWebsiteAPI } from "../../apis/generateWebsiteApi";
import { useUserStateContext } from "../../provider/userStateProvider";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { FireEvent } from "../../helpers/fireEvent";
import { Events } from "../../enums/Events";

export const GenerateWebsite = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const context = useUserStateContext();

  FireEvent(Events.ViewGenerateWebsitePage, {});

  const navigate = useNavigate();
  const onClickGenerate = async (searchString: string) => {
    setIsLoading(true);
    GenerateWebsiteAPI(
      searchString,
      context?.user?.uid ?? "",
      context?.user?.userToken ?? ""
    )
      .then((data) => {
        navigate(`/tweak/${context?.user?.uid}`, { replace: true });
        // setResult(data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div
      className=" w-full flex flex-col md:flex-row justify-center  items-center  mb-10 md:mb-0 relative"
      style={{ height: "100vh" }}
      id="metastart"
    >
      <video
        autoPlay
        muted
        loop
        // type="video/mp4"
        playsInline
        className=" absolute top-0 left-0 z-0 w-full h-full object-cover bg-black"
        src={
          "https://themes.fourthwall.com/themes/assets/11d2f253-34b7-4fdc-b01b-17868cd74972/assets/video-bg-light.mp4?v=ab4ceb1e95742187fe677bf9070cf092638955e28eec6d5b87a3db7455d9948b"
        }
      />
      <div className="flex flex-col justify-center items-center w-2/3 mt-24 md:mt-0">
        <h1 className=" font-raleway text-5xl md:text-7xl z-10">{`<broCODE.ai>`}</h1>
        <SearchField onClickGenerate={onClickGenerate} />
      </div>
    </div>
  );
};
