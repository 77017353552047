import { FireEvent } from "../../helpers/fireEvent";
import { Events } from "../../enums/Events";

export const ContactUs = () => {
  FireEvent(Events.ViewContactUs, {});
  return (
    <div className="min-h-[calc(100vh-72px)]  overflow-x-hidden flex flex-col items-center text-center justify-center pt-5 px-5 lg:px-14 container max-w-[1280px] mx-auto gap-8">
      <h1 className="text-5xl lg:text-7xl font-medium w-fit">
        We'd love to <br /> hear from you
      </h1>
      <div className="lg:basis-1/2 flex flex-col items-center justify-center gap-8">
        <h2 className="text-2xl lg:text-5xl">Book a meeting with our team</h2>
        <p className="font-light max-w-3xl"></p>
        <a
          className="bg-brandBlue border-[1px] border-black text-black h-10 lg:h-12 px-5 w-auto lg:text-xl rounded-lg font-light flex items-center"
          href="https://calendly.com/vivek-metastart"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book a meeting
        </a>
      </div>
    </div>
  );
};
