import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig: any = {
  apiKey: "AIzaSyCQprykvIcAdDi7goQLGBhgKayAcmIquhY",
  authDomain: "ai-metastart-brocode.firebaseapp.com",
  projectId: "ai-metastart-brocode",
  storageBucket: "ai-metastart-brocode.appspot.com",
  messagingSenderId: "364330332904",
  appId: "1:364330332904:web:20ef3df7a9679c9c488f5d",
  measurementId: "G-64Q3KXZ72T",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
